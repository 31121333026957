import request from '@/utils/request'


// 查询服务订单方案列表
export function listServeScheme(query) {
  return request({
    url: '/order/order-serve-scheme/list',
    method: 'get',
    params: query
  })
}

// 查询服务订单方案分页
export function pageServeScheme(query) {
  return request({
    url: '/order/order-serve-scheme/page',
    method: 'get',
    params: query
  })
}

// 查询服务订单方案详细
export function getServeScheme(data) {
  return request({
    url: '/order/order-serve-scheme/detail',
    method: 'get',
    params: data
  })
}

// 新增服务订单方案
export function addServeScheme(data) {
  return request({
    url: '/order/order-serve-scheme/add',
    method: 'post',
    data: data
  })
}

// 修改服务订单方案
export function updateServeScheme(data) {
  return request({
    url: '/order/order-serve-scheme/edit',
    method: 'post',
    data: data
  })
}

// 删除服务订单方案
export function delServeScheme(data) {
  return request({
    url: '/order/order-serve-scheme/delete',
    method: 'post',
    data: data
  })
}
